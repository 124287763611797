import React, {useRef} from "react"
import MathJax from 'react-mathjax2'
import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"


const rel5 = "(P/(f_{ce}' A_{g})) <= 0.30";
const rel6 = "\vrho _{eff} >= 0.05";
const rel7 = "\vrho _{l} <= 0.04";
const rel8 = "s/d_{bl} <= 6";
const rel9 = "\vtext {cover}/D <= 0.1";
const rel10 = "b x h = 400x400mm";
const rel11 = "L = 1800mm";
const rel12 = "f_{c}' = 26MPa";
const rel13 = "f_{y} = 573MPa";
const rel14 = "f_{yh} = 540MPa";
const rel15 = "\vrho_{l}(%) = 1.57";
const rel16 = "\vrho_{t}(%) = 0.57";
const rel17 = "P/(f_{c}' A_{g}) = 0.21÷0.26";
const rel18 = "M/(VH) = 4";
const rel21 = "\vtext {drift(%)} = 5.3";
const rel22 = "\vtext {drift(%)} = 0.25÷0.54";
const rel23 = "\vtext {drift(%)} = 1.0÷2.0";
const rel24 = "\vtext {drift(%)} = 1.5÷2.4";
const rel25 = "\vtext {drift(%)} = 2.06÷2.65";
const rel26 = "\vtext {drift(%)} = 5.1÷2.3";
const rel27 = "\vtext {drift(%)} = 3.5÷4.0";
const rel28 = "\vtext {drift(%)} = 6.0";
const rel29 = "\vtext {drift(%)} = 0.5";
const rel30 = "\vtext {drift(%)} = 0.8";
const rel31 = "\vtext {drift(%)} = 0.7";
const rel32 = "\vtext {drift(%)} = 1.0";
const rel33 = "\vtext {drift(%)} = 2.0";
const rel34 = "\vtext {drift(%)} = 4.0";
const rel35 = "\vtext {drift(%)} = 2.5";
const rel36 = "\vtext {drift(%)} = 5.0";
const rel37 = "\vtext {drift(%)} = 0.564+8.489 \cdot \alpha - 7.804 \cdot \alpha^{2}";
const rel101 = "\alpha = (1+m)(1- \sqrt{n})\sqrt{ \vrho _{s} f_{yh}/f_{c}}";
const rel102 = "m = L/H";
const rel103 = "n = P/(f_{c}A_{g})";
const rel104 = "\vtext {drift(%)} = 0.78";
const rel105 = "\vtext {drift(%)} = 1.1";
const rel106 = "\vtext {drift(%)} = 2.8";
const rel107 = "\vtext {drift(%)} = 4.8";
const rel108 = "\vtext {drift(%)} = 1.0";
const rel109 = "\vtext {drift(%)} = 3.25";
const rel110 = "\vtext {drift(%)} = 4.9";
const rel110b = "n = 0.44÷0.65";
const rel111 = "\vrho _{s}(%) = 0.3";
const rel112 = "m = 1.5÷3.0";
const rel113 = "f_{c}' = 19.8÷44MPa";
const rel114 = "\vrho_{l}(%) = 1.51÷3.53";
const rel115 = "b x h = 278x278mm";
const rel116 = "L = 646mm";
const rel117 = "f_{c}' = 46.3MPa";
const rel118 = "f_{y} = 441MPa";
const rel119 = "f_{yh} = 414MPa";
const rel120 = "\vrho_{l}(%) = 3.94";
const rel121 = "\vrho_{t}(%) = 0.89";
const rel122 = "P/(f_{c}' A_{g}) = -0.26÷0.74";
const rel123 = "M/(VH) = 1.6";
const rel124 = "b x h = 300x300mm";
const rel125 = "b x h = 400x400mm";
const rel126 = "\vrho_{l} = 0.0095 - 0.038";
const rel127 = "\vrho_{t} = 0.0123-0.081";
const rel128 = "f_{c}' = 25MPa";
const rel129 = "f_{y} = 490MPa";
const rel130 = "f_{yh} = 337MPa";
const rel131 = "b x h = 150x300mm";
const rel132 = "f_{c}' = 28÷36MPa";
const rel133 = "f_{y} = 453MPa";
const rel134 = "\vrho_{l}(%) = 2.8";
const rel135 = "\vrho_{t}(%) = 0.6-1.1";
const rel136 = "P/(f_{c}' A_{g}) = 0.08÷0.21";
const rel137 = "M/(VH) = 2.7";
const rel138 = "b x h = 550x550mm";
const rel139 = "f_{c}' = 23.5MPa";
const rel140 = "f_{y} = 300MPa";
const rel141 = "f_{yh} = 300MPa";
const rel142 = "\vrho_{l}(%) = 1.174";
const rel143 = "\vrho_{t}(%) = 1.035";
const rel144 = "P/(f_{c}' A_{g}) = 0.044";
const rel145 = "M/(VH) = 4.1";


const rel38 = "b x h = 550x550mm";
const rel39 = "L = 1050mm";
const rel40 = "f_{c}' = 21÷41MPa";
const rel41 = "f_{y} = 375MPa";
const rel42 = "f_{yh} = 300MPa";
const rel43 = "\vrho_{l}(%) = 1.8";
const rel44 = "\vrho_{t}(%) = 0.61÷1.3";
const rel45 = "P/(f_{c}' A_{g}) = 0.21÷0.66";
const rel45b = "M/(VH) = 1.9";
const rel46 = "\vtext {drift(%)} = 1.5\Delta _{y} / L";
const rel47 = "\Delta_{spall} / L(%) = 1.6(1-P/(f_{ce}' A_{g}))(1+0.10 L/D)";
const rel48 = "\Delta_{bb} / L(%) = 0.8 \cdot 3.25(1.45 + 1.125 P/(f_{c}' A_{g}))(1-P/(f_{c}' A_{g}))(1+0.10 L _{col}/h _{col})";
const rel49 = "\vrho _{s} = 0.12(f _{c}' / f _{yh})(0.5+1.25(P/(f_{c}' A_{g})))";
const rel50 = "\Delta_{bb} / L(%) = 0.8 \cdot 3.25(1.15 + 0.375 P/(f_{c}' A_{g}))(1-P/(f_{c}' A_{g}))(1+0.10 L _{col}/h _{col})";
const rel51 = "\vrho _{s} = 1/3(0.12(f _{c}' / f _{yh})(0.5+1.25(P/(f_{c}' A_{g}))))";
const rel52 = "b x h = 2.4x2.4m";
const rel53 = "L = 9.6m";
const rel54 = "f_{c}' = 33MPa";
const rel55 = "f_{y} = 424MPa";
const rel56 = "f_{yh} = 344MPa";
const rel57 = "\vrho_{l}(%) = 1.2";
const rel58 = "\vrho_{t}(%) = 0.08÷0.15";
const rel59 = "P/(f_{c}' A_{g}) = 0.0";
const rel60 = "M/(VH) = 4.0";
const rel61 = "b x h = 305x305mm - 550x550mm";
const rel62 = "L = 9.6m";
const rel63 = "f_{c}' = 23.6 - 46.5MPa";
const rel64 = "f_{y} = 427-511MPa";
const rel65 = "f_{yh} = 255-508MPa";
const rel66 = "\vrho_{l}(%) = 1.3-3.3";
const rel67 = "\vrho_{t}(%) = 0.56-4.08";
const rel68 = "P/(f_{c}' A_{g}) = 0.1-0.77";
const rel69 = "M/(VH) = 2.86-4.83";
const rel161 = "b x h = 400x400mm";
const rel162 = "L = 1.35m";
const rel163 = "f_{c}' = 23MPa";
const rel164 = "f_{y} = 374MPa";
const rel165 = "f_{yh} = 363MPa";
const rel166 = "\vrho_{l}(%) = 1.58";
const rel167 = "\vrho_{t}(%) = 0.79";
const rel168 = "P/(f_{c}' A_{g}) = -0.046÷0.13";
const rel169 = "M/(VH) = 3.4";

const rel170 = "\vmu = 1.5 - 2";
const rel171 = "\vmu = 4";
const rel172 = "\vmu = 6 - 8";
const rel177 = "L/h = 4";
const rel174 = "f_{c}' = 30MPa";
const rel176 = "f_{y} = 275÷380MPa";
const rel175 = "f_{yh} = 275MPa";
const rel178 = "\vrho_{l}(%) = 1.5÷2.4";
const rel179 = "\vrho_{t}(%) = 0.15÷0.85";
const rel173 = "P/(f_{c}' A_{g}) = 0.12, 0.21, 0.38, 0.53";

const rel180 = "\vmu = 4.2";
const rel181 = "b x h = 400x400mm";
const rel182 = "L = 1800mm";
const rel183 = "f_{c}' = 26MPa";
const rel184 = "f_{y} = 573MPa";
const rel185 = "f_{yh} = 540MPa";
const rel186 = "\vrho_{l}(%) = 1.57";
const rel187 = "\vrho_{t}(%) = 0.57";
const rel188 = "P/(f_{c}' A_{g}) = 0.21÷0.26";

const rel189 = "\vmu = 2.0";
const rel190 = "b x h = 550x550mm";
const rel191 = "L = 1050mm";
const rel192 = "f_{c}' = 21÷41MPa";
const rel193 = "f_{y} = 375MPa";
const rel194 = "f_{yh} =300MPa";
const rel195 = "\vrho_{l}(%) = 1.8";
const rel196 = "\vrho_{t}(%) = 0.61÷1.3";
const rel197 = "P/(f_{c}' A_{g}) = 0.21÷0.66";
const rel198 = "M/(VH) = 1.9";

const rel199 = "\vmu = 4.0";
const rel201 = "\vmu = 6.0";

const rel202 = "\vmu = 4.0÷5.0";
const rel203 = "b x h = 2.42.4m";
const rel204 = "L = 9.6m";
const rel205 = "f_{c}' = 33MPa";
const rel206 = "f_{y} = 424MPa";
const rel207 = "f_{yh} = 344MPa";
const rel208 = "\vrho_{l}(%) = 1.2";
const rel209 = "\vrho_{t}(%) = 0.08÷0.15";
const rel210 = "P/(f_{c}' A_{g}) = 0.0";

const rel211 = "\vmu = 4.6÷2.5";
const rel212 = "b x h = 305x305mm - 550x550mm";
const rel213 = "f_{c}' = 23.6 - 46.5MPa";
const rel214 = "f_{y} = 427 - 511MPa";
const rel215 = "f_{yh} = 255÷508MPa";
const rel216 = "\vrho_{l}(%) = 1.3÷3.3";
const rel217 = "\vrho_{t}(%) = 0.56÷4.08";
const rel218 = "P/(f_{c}' A_{g}) = 0.1÷0.77";
const rel219 = "M/(VH) = 2.86÷4.83";

const rel220 = "\vmu = 6 - 8";
const rel221 = "b x h = 550x550mm";
const rel222 = "L/h = 4";
const rel223 = "f_{c}' = 30MPa";
const rel224 = "f_{yh} = 300MPa";
const rel225 = "\vrho_{l}(%) = 0.3÷0.45";
const rel226 = "P/(f_{c}' A_{g}) = 0.2÷0.7";

const rel227 = "\vmu = 6 - 12";
const rel228 = "b x h = 550x550mm";
const rel229 = "f_{c}' = 23.5 MPa";
const rel230 = "f_{y} = 300 MPa";
const rel231 = "f_{yh} = 300 MPa";
const rel232 = "\vrho_{l}(%) = 1.174";
const rel233 = "\vrho_{t}(%) = 1.035";
const rel234 = "P/(f_{c}' A_{g}) = 0.044";
const rel235 = "M/(VH) = 4.1";




const rel74 = "\Delta_{bb} / L(%) = 3.25(1+(k _{e} \vrho _{eff} d_{bl})/D)(1-P/(f_{c}' A_{g}))(1+0.10 L/D) ";
const rel75 = "k_{e} = 50";
const rel76 = "\Delta_{spall} / L(%) = 1.6(1-P/(f_{c}' A_{g}))(1+0.10 L/D) ";


const LSExperimental = () => {
  //javascript
  const title = 'As Built Piers';
  const metatitle = 'As Built Piers';
  const description = 'Meta Description for As Built Piers';
  const metadescription = description;

  const scrollToRef = (ref) => window.scrollTo({
    left:0,
    top:ref.current.offsetTop,
    behavior: 'smooth'
  });
  
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);
  const Ref10 = useRef(null);
  const Ref11 = useRef(null);
  const Ref12 = useRef(null);
  const Ref13 = useRef(null);
  const Ref14 = useRef(null);
  const Ref15 = useRef(null);
  const Ref16 = useRef(null);
  const Ref17 = useRef(null);
  const Ref18 = useRef(null);
  const Ref19 = useRef(null);
  const Ref20 = useRef(null);
  const Ref21 = useRef(null);
  const Ref22 = useRef(null);
  const Ref23 = useRef(null);
  const Ref24 = useRef(null);
  const Ref25 = useRef(null);
  const Ref26 = useRef(null);
  const Ref27 = useRef(null);
  const Ref28 = useRef(null);
  
  const executeScroll = (e, ref) => {
    e.preventDefault();
    scrollToRef(ref);
  }

  
  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
        <li>
            <a className="more" href="../cylindrical/">Cylindrical Piers</a>
          </li>
          <li>
            <a className="more actclass" href="../rectangular/">Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="../hollow-rectangular/">Hollow Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="../hollow-circular/">Hollow Circular Piers</a>
          </li>
        </ul>
      </nav>
      <div className="content">
        <section className="exp-section">
          <h1>Rectangular Piers</h1>
          <div className="content-table">
            <h2>Table 1: Rectangular Piers: Limit state thresholds in terms of drift</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Drift (%)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref1)}>[1]</button> ACI 341.4R-16 (2016)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel46}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Minor flexural cracks</td>
                  <td rowSpan={3}>Cyclic tests and field observations</td>
                  <td rowSpan={3}>Similar to Goodnight et al. (2016)</td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel47}</MathJax.Node>, Berry and Eberhard (2003)</div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling, yielding of longitudinal reinforcement</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel48}</MathJax.Node><br /><MathJax.Node inline>{rel49}</MathJax.Node><br /><MathJax.Node inline>{rel50}</MathJax.Node><br /><MathJax.Node inline>{rel51}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Buckling of longitudinal reinforcement or/and fracture of transverse reinforcement, partial crushing of concrete core</td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref2)}>[2]</button> Berry, M. and Eberhard, M. (2003)</td>
                  <td rowSpan={2}>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel74}</MathJax.Node><br /><MathJax.Node inline>{rel75}</MathJax.Node> for rectangular columns</div>
                  </MathJax.Context></td>
                  <td>Buckling of longitudinal reinforcement </td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel5}</MathJax.Node><br /><MathJax.Node inline>{rel6}</MathJax.Node><br /><MathJax.Node inline>{rel7}</MathJax.Node><br /><MathJax.Node inline>{rel8}</MathJax.Node><br /><MathJax.Node inline>{rel9}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel76}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Spalling of concrete cover</td>
                </tr>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref3)}>[3]</button> Bousias, S. N., Fardis, M.N., Sotiropoulos, F., Kalteziotis, D., Chronopoulos, M. (2006)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel21}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Longitudinal bar buckling</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel10}</MathJax.Node><br /><MathJax.Node inline>{rel11}</MathJax.Node><br /><MathJax.Node inline>{rel12}</MathJax.Node><br /><MathJax.Node inline>{rel13}</MathJax.Node><br /><MathJax.Node inline>{rel14}</MathJax.Node><br /><MathJax.Node inline>{rel15}</MathJax.Node><br /><MathJax.Node inline>{rel16}</MathJax.Node><br /><MathJax.Node inline>{rel17}</MathJax.Node><br /><MathJax.Node inline>{rel18}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref4)}>[4]</button> Gil, W.D., Park, R., Priestley, M.J.N. (1979)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel22}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Minor cracking and crushing of concrete</td>
                  <td rowSpan={3}>Cyclic loading</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel38}</MathJax.Node><br /><MathJax.Node inline>{rel39}</MathJax.Node><br /><MathJax.Node inline>{rel40}</MathJax.Node><br /><MathJax.Node inline>{rel41}</MathJax.Node><br /><MathJax.Node inline>{rel42}</MathJax.Node><br /><MathJax.Node inline>{rel43}</MathJax.Node><br /><MathJax.Node inline>{rel44}</MathJax.Node><br /><MathJax.Node inline>{rel45}</MathJax.Node><br /><MathJax.Node inline>{rel45b}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel23}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling</td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel24}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended concrete cover spalling</td>
                </tr>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref5)}>[5]</button> Hoshikuma, J., Unjoh, S., and Nagaya, K. (2002)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel25}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling and buckling of longitudinal bars</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel52}</MathJax.Node><br /><MathJax.Node inline>{rel53}</MathJax.Node><br /><MathJax.Node inline>{rel54}</MathJax.Node><br /><MathJax.Node inline>{rel55}</MathJax.Node><br /><MathJax.Node inline>{rel56}</MathJax.Node><br /><MathJax.Node inline>{rel57}</MathJax.Node><br /><MathJax.Node inline>{rel58}</MathJax.Node><br /><MathJax.Node inline>{rel59}</MathJax.Node><br /><MathJax.Node inline>{rel60}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref6)}>[6]</button> Inel, M., Aschheim, M. A., Pantazopoulou, S. J. (2007)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel26}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>20% reduction of the maximum moment at the critical section</td>
                  <td>Cyclic or monotonic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel61}</MathJax.Node><br /><MathJax.Node inline>{rel63}</MathJax.Node><br /><MathJax.Node inline>{rel64}</MathJax.Node><br /><MathJax.Node inline>{rel65}</MathJax.Node><br /><MathJax.Node inline>{rel66}</MathJax.Node><br /><MathJax.Node inline>{rel67}</MathJax.Node><br /><MathJax.Node inline>{rel68}</MathJax.Node><br /><MathJax.Node inline>{rel69}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref7)}>[7]</button> Kehila, F., Remki, M., Bechtoula, H., Mehani, Y., Kibboua, A. (2014)</td>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel27}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Spalling of concrete cover</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel161}</MathJax.Node><br /><MathJax.Node inline>{rel162}</MathJax.Node><br /><MathJax.Node inline>{rel163}</MathJax.Node><br /><MathJax.Node inline>{rel164}</MathJax.Node><br /><MathJax.Node inline>{rel165}</MathJax.Node><br /><MathJax.Node inline>{rel166}</MathJax.Node><br /><MathJax.Node inline>{rel167}</MathJax.Node><br /><MathJax.Node inline>{rel168}</MathJax.Node><br /><MathJax.Node inline>{rel169}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel28}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Longitudinal bar fracture, excessive damage in concrete core</td>
                </tr>
                <tr className="even">
                  <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref8)}>[8]</button> Kim, S. H. and Feng, M. Q. (2003)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel29}</MathJax.Node>, not seismically designed<br /><MathJax.Node inline>{rel30}</MathJax.Node>, seismically designed</div>
                  </MathJax.Context></td>
                  <td>First yield</td>
                  <td rowSpan={4}>Based on Dutta &amp; Mander report</td>
                  <td rowSpan={4}>-</td>
                </tr>
                <tr className="even">
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel31}</MathJax.Node>, not seismically designed<br /><MathJax.Node inline>{rel32}</MathJax.Node>, seismically designed</div>
                  </MathJax.Context></td>
                  <td>Concrete cracking, spalling</td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel33}</MathJax.Node>, not seismically designed<br /><MathJax.Node inline>{rel34}</MathJax.Node>, seismically designed</div>
                  </MathJax.Context></td>
                  <td>Initiation of column collapse</td>
                </tr>
                <tr className="even">
                  <td>LS4</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel35}</MathJax.Node>, not seismically designed<br /><MathJax.Node inline>{rel36}</MathJax.Node>, seismically designed</div>
                  </MathJax.Context></td>
                  <td>Column collapse</td>
                </tr>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref9)}>[9]</button> Lam, S.S.E., Wu, B., Wong, Y.L., Wang, Z.Y., Liu, Z.Q., and Li, C.S. (2003)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel37}</MathJax.Node><br /><MathJax.Node inline>{rel101}</MathJax.Node><br /><MathJax.Node inline>{rel102}</MathJax.Node><br /><MathJax.Node inline>{rel103}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended concrete spalling, major shear crack development</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel110b}</MathJax.Node><br /><MathJax.Node inline>{rel111}</MathJax.Node><br /><MathJax.Node inline>{rel112}</MathJax.Node><br /><MathJax.Node inline>{rel113}</MathJax.Node><br /><MathJax.Node inline>{rel114}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref10)}>[10]</button> Lejano, B, Shirai, N. Adachi, H., Ono, A. (1992)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel104}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of transverse reinforcement</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel115}</MathJax.Node><br /><MathJax.Node inline>{rel116}</MathJax.Node><br /><MathJax.Node inline>{rel117}</MathJax.Node><br /><MathJax.Node inline>{rel118}</MathJax.Node><br /><MathJax.Node inline>{rel119}</MathJax.Node><br /><MathJax.Node inline>{rel120}</MathJax.Node><br /><MathJax.Node inline>{rel121}</MathJax.Node><br /><MathJax.Node inline>{rel122}</MathJax.Node><br /><MathJax.Node inline>{rel123}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref11)}>[11]</button> Lu, Y., Gu, X., Guan, J. (2005)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel105}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Functional performance level</td>
                  <td rowSpan={3}>Monte Carlo simulation</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel124}</MathJax.Node><br /><MathJax.Node inline>{rel125}</MathJax.Node><br /><MathJax.Node inline>{rel126}</MathJax.Node><br /><MathJax.Node inline>{rel127}</MathJax.Node><br /><MathJax.Node inline>{rel128}</MathJax.Node><br /><MathJax.Node inline>{rel129}</MathJax.Node><br /><MathJax.Node inline>{rel130}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel106}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Damage control performance level</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel107}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Ultimate performance level</td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref12)}>[12]</button> Pujol, S. (2002)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel108}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Long. Reinforcement yielding, minor spalling of concrete</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel131}</MathJax.Node><br /><MathJax.Node inline>{rel132}</MathJax.Node><br /><MathJax.Node inline>{rel133}</MathJax.Node><br /><MathJax.Node inline>{rel134}</MathJax.Node><br /><MathJax.Node inline>{rel135}</MathJax.Node><br /><MathJax.Node inline>{rel136}</MathJax.Node><br /><MathJax.Node inline>{rel137}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel109}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended concrete spalling, long. Reinforcement buckling</td>
                </tr>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref13)}>[13]</button> Tsuno, K. and Park, R. (2004)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel110}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended spalling of concrete, buckling and/or fracture of long. bars</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel138}</MathJax.Node><br /><MathJax.Node inline>{rel139}</MathJax.Node><br /><MathJax.Node inline>{rel140}</MathJax.Node><br /><MathJax.Node inline>{rel141}</MathJax.Node><br /><MathJax.Node inline>{rel142}</MathJax.Node><br /><MathJax.Node inline>{rel143}</MathJax.Node><br /><MathJax.Node inline>{rel144}</MathJax.Node><br /><MathJax.Node inline>{rel145}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref1}>ACI Committee 341 (2016). ACI 341.4R-16 Report on the Seismic Design of Bridge Columns Based on Drift, ISBN: 978-1-945487-02-6. </li>
              <li ref={Ref2}>Berry, M., Eberhard, M. (2003). Performance Models for Flexural Damage in Reinforced Concrete Columns, Report PEER 2003/18, Department of Civil and Environmental Engineering, University of Washington.</li>
              <li ref={Ref3}>Bousias, S.N., Fardis, M.N., Sotiropoulos, F., Kalteziotis, D., Chronopoulos, M., Influence of transverse reinforcement configuration in seismic performance of reinforced concrete columns, 15th Hellenic Concrete conference, Alexandroupoli, Greece, Β3.44, 2006, pp. 507-516 (in Greek).</li>
              <li ref={Ref4}>Gill, W.D., Park, R., and Priestley, M.J.N.. Ductility of Rectangular Reinforced Concrete Columns With Axial Load, Report 79-1, Department of Civil Engineering, University of Canterbury, Christchurch, New Zealand, 1979.</li>
              <li ref={Ref5}>Hoshikuma, J., Unjoh, S., and Nagaya, K., “Flexural Ductility of Full-Scale bridge Columns subjected to Cyclic Loading” Proceedings of 1st fib Congress, Osaka, October 2002.</li>
              <li ref={Ref6}>Inel, M. Aschheim, M. A., Pantazopoulou, S. J. (2007). Seismic deformation capacity indices for concrete columns: model estimates and experimental results, Magazine of Concrete Research, Vol. 59, No. 4, pp 297-310, <a target="_blank" href="https://doi.org/10.1680/macr.2007.59.4.297">https://doi.org/10.1680/macr.2007.59.4.297.</a></li>
              <li ref={Ref7}>Kehila, F., Remki, M., Bechtoula, H., Mehani, Y., Kibboua, A. (2014). EFFET OF LOADING HISTORY ON FRAGİLİTY CURVES OF REİNFORCED CONCRETE BRIDGE PIERS UNDER MULTIDIRECTIONAL LOADING, Second European Conference on Earthquake Engineering and Seismology, Istanbul, 25-29 August, 2014. </li>
              <li ref={Ref8}>Kim, S. H. and Feng, M. Q. (2003). Fragility analysis of bridges under ground motion with spatial variation, International Journal of Non-Linear Mechanics, Vol. 38, pp 705-721, <a target="_blank" href="https://doi.org/10.1016/S0020-7462(01)00128-7">https://doi.org/10.1016/S0020-7462(01)00128-7.</a></li>
              <li ref={Ref9}>Lam, S.S.E., Wu, B., Wong, Y.L., Wang, Z.Y., Liu, Z.Q., and Li, C.S. Drift Capacity of Rectangular Reinforced Concrete Columns with Low Lateral Confinement and High-Axial Load, Journal of Structural Engineering, Vol. 129, No. 6, June 2003, pp. 733-742, <a target="_blank" href="https://doi.org/10.1061/(ASCE)0733-9445(2003)129:6(733)">https://doi.org/10.1061/(ASCE)0733-9445(2003)129:6(733).</a></li>
              <li ref={Ref10}>Lejano, B., Shirai, N., Adachi, H., Ono, A. (1992). Deformation properties and shear resistance mechanism of reinforced concrete column with high and fluctuating axial force, Earthquake Engineering, Tenth World Conference, 1992, Rotterdam. </li>
              <li ref={Ref11}>Lu, Y., Gu., X., Guan, J. (2005). Probabilistic Drift Limits and Performance Evaluation of Reinforced Concrete Columns, Journal of Structural Engineering, ASCE, Vol. 131, No. 6, pp 966-978, <a target="_blank" href="https://doi.org/10.1061/(ASCE)0733-9445(2005)131:6(966)">https://doi.org/10.1061/(ASCE)0733-9445(2005)131:6(966).</a></li>
              <li ref={Ref12}>Pujol, S. (2002). Drift capacity of reinforced concrete columns subjected to displacement reversals, PhD Thesis, Purdue University.</li>
              <li ref={Ref13}>Tsuno, K. and Park, R. (2004). EXPERIMENTAL STUDY OF REINFORCED CONCRETE BRIDGE PIERS SUBJECTED TO BI-DIRECTIONAL QUASI-STATIC LOADING, Journal of Structural Mechanics and Earthquake Engineering, JSCE, Vol. 21, No. 1, pp 11-26, <a target="_blank" href="https://doi.org/10.2208/jscej.2004.752_11">https://doi.org/10.2208/jscej.2004.752_11.</a></li>
            </ol>
          </div>
        </section>
        <section className="exp-section">
          <div className="content-table">
            <h2>Table 2: Rectangular Piers: Limit state thresholds in terms of displacement ductility</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Displacement ductility (μ<sub>d</sub>)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref14)}>[1]</button> Ang, B. G., Priesteley, M. J. N., Park, R. (1981)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel170}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cracking and initiation of crushing</td>
                  <td rowSpan={3}>Cyclic loading</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel173}</MathJax.Node><br /><MathJax.Node inline>{rel174}</MathJax.Node><br /><MathJax.Node inline>{rel175}</MathJax.Node><br /><MathJax.Node inline>{rel176}</MathJax.Node><br /><MathJax.Node inline>{rel177}</MathJax.Node><br /><MathJax.Node inline>{rel178}</MathJax.Node><br /><MathJax.Node inline>{rel179}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel171}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel172}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Longitudinal bars buckling</td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref15)}>[2]</button> Bousias, S. N., Fardis, M.N., Sotiropoulos, F., Kalteziotis, D., Chronopoulos, M. (2006)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel180}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Longitudinal bar buckling</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel181}</MathJax.Node><br /><MathJax.Node inline>{rel182}</MathJax.Node><br /><MathJax.Node inline>{rel183}</MathJax.Node><br /><MathJax.Node inline>{rel184}</MathJax.Node><br /><MathJax.Node inline>{rel185}</MathJax.Node><br /><MathJax.Node inline>{rel186}</MathJax.Node><br /><MathJax.Node inline>{rel187}</MathJax.Node><br /><MathJax.Node inline>{rel188}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref16)}>[3]</button> Gil, W.D., Park, R., Priestley, M.J.N. (1979)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel189}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Minor cracking and crushing of concrete</td>
                  <td rowSpan={3}>Cyclic loading</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel190}</MathJax.Node><br /><MathJax.Node inline>{rel191}</MathJax.Node><br /><MathJax.Node inline>{rel192}</MathJax.Node><br /><MathJax.Node inline>{rel193}</MathJax.Node><br /><MathJax.Node inline>{rel194}</MathJax.Node><br /><MathJax.Node inline>{rel195}</MathJax.Node><br /><MathJax.Node inline>{rel196}</MathJax.Node><br /><MathJax.Node inline>{rel197}</MathJax.Node><br /><MathJax.Node inline>{rel198}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel199}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel201}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended concrete cover spalling</td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref17)}>[4]</button> Hoshikuma, J., Unjoh, S., and Nagaya, K. (2002)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel202}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling and buckling of longitudinal bars</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel203}</MathJax.Node><br /><MathJax.Node inline>{rel204}</MathJax.Node><br /><MathJax.Node inline>{rel205}</MathJax.Node><br /><MathJax.Node inline>{rel206}</MathJax.Node><br /><MathJax.Node inline>{rel207}</MathJax.Node><br /><MathJax.Node inline>{rel208}</MathJax.Node><br /><MathJax.Node inline>{rel209}</MathJax.Node><br /><MathJax.Node inline>{rel210}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref18)}>[5]</button> Inel, M., Aschheim, M. A., Pantazopoulou, S. J. (2007)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel211}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>20% reduction of the maximum moment at the critical section</td>
                  <td>Cyclic or monotonic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel212}</MathJax.Node><br /><MathJax.Node inline>{rel213}</MathJax.Node><br /><MathJax.Node inline>{rel214}</MathJax.Node><br /><MathJax.Node inline>{rel215}</MathJax.Node><br /><MathJax.Node inline>{rel216}</MathJax.Node><br /><MathJax.Node inline>{rel217}</MathJax.Node><br /><MathJax.Node inline>{rel218}</MathJax.Node><br /><MathJax.Node inline>{rel219}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref19)}>[6]</button> Priestley, M. J. N. and Park, R. (1987)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel220}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Longitudinal bar buckling</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel221}</MathJax.Node><br /><MathJax.Node inline>{rel222}</MathJax.Node><br /><MathJax.Node inline>{rel223}</MathJax.Node><br /><MathJax.Node inline>{rel224}</MathJax.Node><br /><MathJax.Node inline>{rel225}</MathJax.Node><br /><MathJax.Node inline>{rel226}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref20)}>[7]</button> Tsuno, K. and Park, R. (2004)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel227}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended spalling of concrete, buckling and/or fracture of long. bars</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel228}</MathJax.Node><br /><MathJax.Node inline>{rel229}</MathJax.Node><br /><MathJax.Node inline>{rel230}</MathJax.Node><br /><MathJax.Node inline>{rel231}</MathJax.Node><br /><MathJax.Node inline>{rel232}</MathJax.Node><br /><MathJax.Node inline>{rel233}</MathJax.Node><br /><MathJax.Node inline>{rel234}</MathJax.Node><br /><MathJax.Node inline>{rel235}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref14}>Ang, B.G., Priestley, M.J.N., and Park, R., Ductility of Reinforced Bridge Piers Under Seismic Loading, Report 81-3, Department of Civil Engineering, University of Canterbury, Christchurch, New Zealand, 1981.</li>
              <li ref={Ref15}>Bousias, S.N., Fardis, M.N., Sotiropoulos, F., Kalteziotis, D., Chronopoulos, M., Influence of transverse reinforcement configuration in seismic performance of reinforced concrete columns, 15th Hellenic Concrete conference, Alexandroupoli, Greece, Β3.44, 2006, pp. 507-516 (in Greek).</li>
              <li ref={Ref16}>Gill, W.D., Park, R., and Priestley, M.J.N.. Ductility of Rectangular Reinforced Concrete Columns With Axial Load, Report 79-1, Department of Civil Engineering, University of Canterbury, Christchurch, New Zealand, 1979.</li>
              <li ref={Ref17}>Hoshikuma, J., Unjoh, S., and Nagaya, K., “Flexural Ductility of Full-Scale bridge Columns subjected to Cyclic Loading” Proceedings of 1st fib Congress, Osaka, October 2002.</li>
              <li ref={Ref18}>Inel, M. Aschheim, M. A., Pantazopoulou, S. J. (2007). Seismic deformation capacity indices for concrete columns: model estimates and experimental results, Magazine of Concrete Research, Vol. 59, No. 4, pp 297-310, <a href="https://doi.org/10.1680/macr.2007.59.4.297">https://doi.org/10.1680/macr.2007.59.4.297.</a></li>
              <li ref={Ref19}>Priestley, M. J. N. and Park, R. (1987). Strength and Ductility of Concrete Bridge Columns Under Seismic Loading, ACI Structural Journal, No. 84-S8., pp 61-76, <a href="https://doi.org/10.14359/2800">https://doi.org/10.14359/2800.</a></li>
              <li ref={Ref20}>Tsuno, K. and Park, R. (2004). EXPERIMENTAL STUDY OF REINFORCED CONCRETE BRIDGE PIERS SUBJECTED TO BI-DIRECTIONAL QUASI-STATIC LOADING, Journal of Structural Mechanics and Earthquake Engineering, JSCE, Vol. 21, No. 1, pp 11-26, <a href="https://doi.org/10.2208/jscej.2004.752_11">https://doi.org/10.2208/jscej.2004.752_11.</a></li>
            </ol>
          </div>
        </section>
      </div>
      
      
    </Layout>
  )
}

export default LSExperimental
